// JS Dependencies: Bootstrap & JQuery
//import 'jquery';
//import 'bootstrap';
// CSS Dependencies: Bootstrap & Bootstrap icons
//import 'bootstrap-icons/font/bootstrap-icons.css';
//import 'bootstrap/dist/css/bootstrap.css';
// Custom JS imports
// ... none at the moment
// Custom CSS imports
import '../scss/site.scss';
console.log('The \'site\' bundle has been loaded!');
import { initFlowbite } from 'flowbite';
initFlowbite();
